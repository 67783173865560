// This is used as a global file, to store global functions and globals CONSTANTS
// Add a namespace so that we can split funcs and consts
// Remember to declare everything (methods and values) as STATIC, otherwise you
//      will need to instantiate a new object of class func/consts before
//      being able to use it

import { Customer } from "../models/customer";

export namespace Globals {

    export class funcs {

        static generatePassword() : string {
            return Math.random().toString(36).slice(-6); //very quick JS one-liner to generate 6 char password.
        }

        static  sortByKeyAsc(array, key) {
                return array.sort(function (a, b) {
                    var x = a[key];
                    var y = b[key];
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                });
            }

        static  sortByKeyDesc(array, key) {
                return array.sort(function (a, b) {
                    var x = a[key];
                    var y = b[key];
                    return ((x > y) ? -1 : ((x < y) ? 1 : 0));
                });
            }

        static numberOnly(event): boolean {
                const charCode = (event.which) ? event.which : event.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                  return false;
                }
                return true;
              }

        private static days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

        static getDayName(dow: number): string {
            return this.days[dow - 1];
        }

        static getCustomer()
        {
            var cust = new Customer();
            var storageCustomer = JSON.parse(localStorage.getItem('customerData')!) as Customer;
            var storageFleet = JSON.parse(localStorage.getItem('fleetData')!) as Customer;
            if( storageCustomer == null && storageFleet == null)
            {
              cust.name = "Replace Name";
              cust.email = "Replace Email";
              cust.cell = "Replace Cell";
              cust.vreg = ""
            }
            if( storageCustomer != null)
            {
              cust.name = storageCustomer.name;
              cust.email = storageCustomer.email;
              cust.cell = storageCustomer.cell;
              cust.vreg = storageCustomer.vreg;
              cust.customerId = storageCustomer.customerId;
            }
            if (storageFleet != null)
            {
              cust.name = storageFleet.name;
              cust.email = storageFleet.email;
              cust.cell = storageFleet.cell;
              cust.vreg = storageFleet.vreg;
              cust.customerId = storageFleet.customerId;
            }
            return cust
        }

         static omit_special_char(event)
        {   
          var k;  
          k = event.charCode;
          return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
        }

    }

    export class consts {
        static matrixObject = [{'id':'external' , 'name': 'External'},{'id': 'retail', 'name': 'Retail'},{'id': 'online', 'name': 'Online'},
                               {'id': 'wholesale', 'name': 'wholesale'},{'id': 'internal', 'name': 'Internal'},{'id': 'c1' , 'name': 'Custom One'},
                               {'id': 'c2', 'name': 'Custom Two'},{'id': 'c3', 'name': 'Custom Three'}, {'id': 'c4', 'name': 'Custom Four'}];

        static matrixOptions = [{'id': 0,'text': 'Prices Set with Custom Logic negotiated with StockFinder'},
                                {'id': 1 ,'text': 'Use the below Price Matrix.'}]

        static CutOffTimes = ['Before 08:00am' , '09:00am ' , '10:00am' , '11:00am' , '12:00pm' , '13:00pm' , '14:00pm' , '15:00pm', 'After 15:00pm'];
        
        static ExpectedDeliveryTimes = ['09:00am Same Day' , '10:00am Same Day' , '11:00am Same Day' , '12:00pm Same Day' , '13:00pm Same Day' , '14:00pm Same Day' , '15:00pm Same Day' , '16:00pm Same Day', 'Same Day Delivery (pm)', 'Next Day Delivery (am)' , 'Next Day Delivery (pm)','08:00am Next Day' , '09:00am Next Day' , '10:00am Next Day' , '11:00am Next Day' , '12:00pm Next Day' , '13:00pm Next Day' , '14:00pm Next Day' , '15:00pm Next Day'];

        
        static VERSION_NUMBER = "3.4.5";


        static VERSION_NUMBER_INT =  Number(consts.VERSION_NUMBER.split(".")[0] + (consts.VERSION_NUMBER.split(".")[1].padStart(2,'0')) + (consts.VERSION_NUMBER.split(".")[2].split("_")[0].replace(/\D/g,'').padStart(3,'0')));
    }




}

