import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { PosService } from "../pos/pos.service";
import { PosAuditService } from "../pos/posaudit.service";
import { SalesService } from "../sales/sales.service";
import { NotificationService } from "../shared/notification.service";
import { ReportsService } from "../shared/reports.service";
import { PosAuditEntry, PosAuditEntryType } from "./pos";


import {Injector} from "@angular/core";
import { WhatsappService } from "../whatsapp/whatsapp.service";
import { SendReportService } from "../widgets/send-report/send-report.service";

export class ServiceLocator {
    static injector: Injector;
}

export class CommunicationInfo {
    cell: string
    email: string
    name: string
}

export interface ICommunication {
    //properties


    //members
    print() : void;
    sendEmail() : void;
    sendWhatsApp() : void;
}

export abstract class CommunicationBase implements ICommunication {

    isLoading: boolean;

    type: string;
    reference: string;
    primaryKey: number;

    cell: string;
    email: string;

    previewUrl: string;

    disableWhatsapp: boolean = true;

    canDownload: boolean = true;
    canPrint: boolean = true;
    canPreview: boolean = true;
    canEmail: boolean = true;
    canCell: boolean = true;

    abstract print() : void;
    abstract sendEmail(): void;
    abstract sendWhatsApp(): void;
    abstract download() : void;

    public openPreview() {
        window.open(this.previewUrl,"_blank");
    }

    get isValidEmail() : boolean {
        var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{1,8}$/g;
        if (this.email) {
          return re.test(this.email);
        } 
        else {
          return false;
        }
    }
}

export class InvoiceCommunication extends CommunicationBase {

    constructor(reference: string, key: number) {
        super();
        this.type = "Invoice";
        this.reference = reference;
        this.primaryKey = key;
        this.isLoading = true;
        this.previewUrl = environment.b2c_url+ `/invoice/${reference}`;
        let salesService = ServiceLocator.injector.get(SalesService);
        salesService.communicationDetails(key).subscribe(
            val => {
                this.isLoading = false;
                this.cell = val.cell;
                this.email =  val.email;
            }
        )        
    }

    print(): void {
        let reportService = ServiceLocator.injector.get(ReportsService);
        reportService.openInvoice(this.primaryKey);
    }

    download(): void {
        let reportService = ServiceLocator.injector.get(ReportsService);
        reportService.downloadInvoice(this.primaryKey);
    }

    sendEmail(): void {
        this.isLoading = true;
        const salesService = ServiceLocator.injector.get(SalesService);
        const notification = ServiceLocator.injector.get(NotificationService);
        salesService.emailInvoice(this.primaryKey, this.email).subscribe(
            val => {              
              notification.showSuccess("Invoice emailed");
              this.isLoading = false;
            },
            error => {
              notification.handleError(error);
              this.isLoading = false;
            })
    }

    sendWhatsApp(): void {
        const notification = ServiceLocator.injector.get(NotificationService);
        notification.showWarning("Sending invoices over WhatsApp not enabled yet")
    }
}

export class QuoteCommunication extends CommunicationBase {

    constructor(reference: string, key: number, userId: number, cell: string, email: string, private customerId: number) {
        super();
        this.type = "Quote";
        this.reference = reference;
        this.primaryKey = key;
        this.cell = cell;
        this.email =  email;
        this.previewUrl = environment.b2c_url+ `/quote/${reference}/${userId}`;
        //window.open(environment.b2c_url + `/quote/${this.posQuote.quoteRef}/${this.authService.user.user_id}`,"_blank")
    }

    print(): void {
        //let reportService: ReportsService = new ReportsService();
        let reportService = ServiceLocator.injector.get(ReportsService);
        reportService.openQuote(this.primaryKey, this);
    }

    download(): void {
        let reportService = ServiceLocator.injector.get(ReportsService);
        reportService.downloadQuote(this.primaryKey, this);
    }

    sendEmail(): void {
        const auditService = ServiceLocator.injector.get(PosAuditService);
        const posService = ServiceLocator.injector.get(PosService);
        const notification = ServiceLocator.injector.get(NotificationService);
        //add audit actions and subscribe to save async
        auditService.addAction(new PosAuditEntry(this.primaryKey, PosAuditEntryType.EmailedQuote, this.customerId, this.email))
        auditService.SaveActionsSub().subscribe(
            _ => {

            }
        );
        this.isLoading = true;
        posService.emailQuote(this.primaryKey, this.email).subscribe(
            val => {              
              notification.showSuccess("Quote emailed");
              this.isLoading = false;
            },
            error => {
              notification.handleError(error);
              this.isLoading = false;
            })
    }

    sendWhatsApp(): void {
        const auditService = ServiceLocator.injector.get(PosAuditService);
        const posService = ServiceLocator.injector.get(PosService);
        const notification = ServiceLocator.injector.get(NotificationService);
        const waService = ServiceLocator.injector.get(WhatsappService);
        let sendReportService = ServiceLocator.injector.get(SendReportService);
        //add audit actions and subscribe to save async
        auditService.addAction(new PosAuditEntry(this.primaryKey, PosAuditEntryType.SendWhatsApp, this.customerId, this.cell))
        auditService.SaveActionsSub().subscribe(
            _ => {

            }
        );
        this.isLoading = true;
        posService.whatsappQuote(this.primaryKey, this.cell).subscribe(
            val => {              
              notification.showSuccess("Quote sent via Whatsapp");
              sendReportService.removeReport();
              waService.GetAllMessages();
              this.isLoading = false;
            },
            error => {
              notification.handleError(error);
              this.isLoading = false;
            })
    }
}

export class ReportCommunication extends CommunicationBase{

    constructor(reference: string, userId: number) {
        super();
        this.type = "Report";
        this.reference = reference;
        this.primaryKey = userId;
        this.previewUrl = environment.b2c_url+ `/reports/leads_report/${userId}`;
        this.canCell = false;
        this.canEmail = false;
        this.canPreview = false;
    }

    print(): void {
        //let reportService: ReportsService = new ReportsService();
        let reportService = ServiceLocator.injector.get(ReportsService);
        reportService.openLeadsReport(this.primaryKey, this);
    }

    download(): void {
        let reportService = ServiceLocator.injector.get(ReportsService);
        reportService.downloadLeadsReport(this.primaryKey, this);
    }

    sendEmail(): void {
        const notification = ServiceLocator.injector.get(NotificationService);
        notification.showWarning("Sending reports over email not enabled yet")
    }

    sendWhatsApp(): void {
        const notification = ServiceLocator.injector.get(NotificationService);
        notification.showWarning("Sending reports over WhatsApp not enabled yet")
    }
}