<div class="container-fluid">
    <div nz-row [nzJustify]="'center'">
        <div nz-col>
            <h2><b>Price lists</b></h2>
        </div>
    </div>
</div>

<br>

<div class="row" *ngIf="showButtons">
    <ng-template *ngIf="this.authService.user.isManagedSF; then isManagedSF ; else isExternal ">
    </ng-template>
    <ng-template #isManagedSF>
        <div class="col-4">
            <div class="text-center">
                <button nz-button nzType="primary" (click)="exportToExcel('Pricelist')" style="margin-left: 10px; margin-right: 10px;">
                        <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                        Export PL
                    </button>
                <button nz-button nzType="primary" (click)="toggleuploadSoh()" style="margin-left: 10px; margin-right: 10px;" disabled="true">
                        <i nz-icon nzType="upload" nzTheme="outline"></i>
                        Upload PL
                    </button>
                <button nz-button nzType="primary" *ngIf="authService.hasClaims(['STA'])" (click)="toggleaddStock()" style="margin-left: 10px; margin-right: 10px;">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                        Add New Stock
                 </button>
                <app-video-widget [videoId]="4" style="float: inline-end;"></app-video-widget>
            </div>
        </div>
        <div class="col-4">
            <nz-input-group nzSuffixIcon="search">
                <input type="text" nz-input placeholder="Filter Price List" [(ngModel)]="listFilter" (ngModelChange)="resetTable()"/>
            </nz-input-group>
        </div>
    </ng-template>
    <ng-template #isExternal>
        <ng-container *ngIf="authService.hasClaims(['STA'])">
            <div class="col-4">
                <div class="text-center">
                    <button nz-button nzType="primary" (click)="exportToExcel('Stocklist')">
                            <i nz-icon nzType="file-excel" nzTheme="outline"></i>
                                Export Stock list
                    </button>
                    <!-- <button nz-button nzType="primary" (click)="toggleupload()" style="margin-left: 2%;">
                        <i nz-icon nzType="upload" nzTheme="outline"></i>
                                Upload Stock list
                    </button> -->
                </div>
            </div>
            <div class="col-4">
                <nz-input-group nzSuffixIcon="search">
                    <input type="text" nz-input placeholder="Filter Price List" [(ngModel)]="listFilter" (ngModelChange)="resetTable()" />
                </nz-input-group>
            </div>
        </ng-container>
    </ng-template>
</div>

<p></p>

<div nz-col *ngIf="this.showupload || this.showuploadSoh || this.showaddStock">
    <button nz-button nzType="primary" type="button" (click)="toggle()"> 
        <i nz-icon nzType="arrow-left" nzTheme="outline" style="font-size: 15px;"></i>
        Back to List 
    </button>
</div>

<br>
<div *ngIf="this.showmain">
    <nz-table *ngIf="!showDemoSohExport" id="pldata" #pldata [nzData]="priceList.items"  [nzFrontPagination]="false" [nzLoading]="isLoading">
        <thead id="headers">
            <tr>
                <th>Description</th>
                <th *ngIf="showForSOH">Brand</th>
                <th>Stock Code</th>
                <th>Cost</th>
                <th>Soh</th>
                <ng-container *ngIf="showForSOH">
                    <th *ngFor="let priceName of [priceList.priceNames.plDefault, priceList.priceNames.plRetail, priceList.priceNames.plWholesale, priceList.priceNames.plInternal, priceList.priceNames.plOnline, priceList.priceNames.plCustom1, priceList.priceNames.plCustom2, priceList.priceNames.plCustom3, priceList.priceNames.plCustom4]">
                        {{ priceName }}
                    </th>
                </ng-container>
                <th *ngIf="showForSOH">List</th>
                <th *ngIf="authService.hasClaims(['STA']) && showLc && showForSOH">Last Cost</th>
                <th *ngIf="authService.hasClaims(['STA']) && showRt && showForSOH">Running Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of pldata.data | filter: listFilter: listFilterArray: 0: pageSize">
                <td>
                    <span nz-tooltip nzTooltipPlacement="top" [nzTooltipTitle]="item.descriptionLong"*ngIf="this.showR">
                                   <i nz-icon nzType="info-circle" nzTheme="outline" class="back"></i>
                            </span> {{ item.description }}
                </td>
                <!-- <td *ngIf="this.showForSOH" hidden>{{ item.msfid }}</td> -->
                <td *ngIf="this.showForSOH">{{ item.brand }}</td>
                <td>
                    {{ item.stockCode }}
                    
                </td>
                <td>
                    <span class="text-info-style" *ngIf="this.showR">R </span> {{ item.cost }}
                </td>
                <td style="text-align: center;">
                    {{item.soh}}
                    <button nz-button nzType="primary" *ngIf="authService.hasClaims(['STA']) && this.authService.user.isManagedSF && this.showForSOH" (click)="showQtyModal(item)">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </button>
                </td>
                <ng-container *ngIf="showForSOH">
                    <ng-container *ngFor="let price of [
                        { key: 'pricedefault', soKey: 'overridePriceDefault', ogKey: 'originalPriceDefault'},
                        { key: 'priceretail', soKey: 'overridePriceRetail', ogKey: 'originalPriceRetail' }, 
                        { key: 'pricewholesale', soKey: 'overridePriceWholesale', ogKey: 'originalPriceWholesale' }, 
                        { key: 'priceinternal', soKey: 'overridePriceInternal', ogKey: 'originalPriceInternal' }, 
                        { key: 'priceonline', soKey: 'overridePriceOnline', ogKey : 'originalPriceOnline' }, 
                        { key: 'pricecustom1', soKey: 'overridePriceCustom1', ogKey: 'originalPriceCustom1' }, 
                        { key: 'pricecustom2', soKey: 'overridePriceCustom2', ogKey: 'originalPriceCustom2' }, 
                        { key: 'pricecustom3', soKey: 'overridePriceCustom3' , ogKey: 'originalPriceCustom3'}, 
                        { key: 'pricecustom4', soKey: 'overridePriceCustom4', ogKey: 'originalPriceCustom4' }
                    ]">
                        <td>
                            <ng-container *ngIf="item[price.soKey] == null || (item[price.key] == 0 && item[price.ogKey] == 0) ; else overridePrice">
                                <span class="text-info-style" *ngIf="showR">R </span> {{ item[price.key] }}
                            </ng-container>
                            <ng-template #overridePrice>
                                <button nz-button nzType="primary" nz-tooltip nzTooltipPlacement="topLeft" 
                                        nzTooltipTitle="Override pricing in effect. Original price is R {{item[price.ogKey]}}">
                                    R {{ item[price.soKey] }}
                                </button>
                            </ng-template>
                        </td>
                    </ng-container>
                </ng-container>
                <td><span class="text-info-style" *ngIf="this.showR">R </span>{{item.retail}}</td>
                <td *ngIf="authService.hasClaims(['STA']) && showLc && this.showForSOH">
                    <span class="text-info-style" *ngIf="this.showR">R </span> {{ item.lastCost }}
                </td>
                <td *ngIf="authService.hasClaims(['STA']) && showRt && this.showForSOH">
                    <span class="text-info-style" *ngIf="this.showR">R </span> {{ item.runningTotal }}
                </td>
            </tr>
        </tbody>
    </nz-table>
    <!--Template-->
    <nz-table id="pldataTemp" *ngIf="showDemoSohExport" [nzFrontPagination]="false">
        <thead>
            <tr>
                <th> Description</th>
                <th>Stock Code - 'add #'</th>
                <th>Cost</th>
                <th>Soh</th>
            </tr>
        </thead>
        <tbody class="tbody">
            <tr>
                <td colspan="4">
                    !!! Remove this line and The Example line below before uploading !!!
                </td>
            </tr>
            <tr>
                <td>
                    e.g 2656517 Dun
                </td>
                <td>
                    e.g #150001
                </td>
                <td>
                    e.g 1000
                </td>
                <td>
                    e.g 5
                </td>
            </tr>
        </tbody>
    </nz-table>
</div>
<div *ngIf="this.showupload" class="text-center">
    <div nz-row [nzJustify]="'center'">
        <div nz-col nzSpan="8">
            <img src="assets/Images/excel.png" alt="" width="150" class="mb-4">
            <h3 class="display-4">Upload Price list</h3>
            <h5>After downloading the table to excel, you can edit the pricing and upload it here.If you use your own template the upload will not work.</h5>
            <form nz-form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()" enctype="multipart/form-data" class="text-center">
                <nz-form-item class="text-center rounded-pill">
                    <nz-form-control [nzSpan]="10">
                        <input #UploadFileInput name="formFile" formControlName="formFile" type="file" (change)="onFileSelect($event)">
                    </nz-form-control>
                </nz-form-item>
                <button nz-button nzType="primary" type="submit" (click)="this.isLoadingOne = true" [nzLoading]="isLoadingOne">Upload</button>
            </form>
        </div>
    </div>
</div>
<div *ngIf="this.showuploadSoh">
    <br>
    <form [formGroup]="fileUploadForm" (ngSubmit)="onFormSubmit()" enctype="multipart/form-data">
        <div class="container py-5">
            <!-- For demo purpose -->
            <header class="text-white text-center">
                <h2>Attention !!</h2>
                <h3>All stock will be overridden to match the sheet uploaded</h3>
                <h6 style="color: var(--textcolor);">Only the exported SF Template can be uploaded </h6>
                <i nz-icon nzType="warning" nzTheme="outline" style="color: rgb(236, 216, 34);font-size: 100px;"></i>
                <!-- <img src="assets/Images/excel.png" alt="" width="150" class="mb-4"> -->
            </header>
            <div class="row py-4">
                <div class="col-lg-6 mx-auto text-center">
                    <!-- Upload image input-->
                    <div class="input-group mb-3 px-2 py-2 rounded-pill bg-color-primary">
                        <input #UploadFileInput name="formFile" formControlName="formFile" id="customFile" type="file" (change)="onFileSelect($event)">
                        <label id="customFile-label" for="customFile" class="font-weight-light">{{fileInputLabel}}</label>
                        <div class="input-group-append">
                            <label for="customFile" class="btn btn-light m-0 rounded-pill px-4">
                                      <i nz-icon nzType="upload" nzTheme="outline" class="mr-2"></i>
                                      <small class="text-uppercase font-weight-bold">
                                          Choose file
                                      </small>
                                  </label>
                        </div>
                    </div>
                    <div class="row text-center">
                        <div class="col-lg-12 text-center">
                            <br>
                            <button nz-button nzType="primary" type="submit" (click)="this.isLoadingOne = true" [nzLoading]="isLoadingOne">Upload</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div *ngIf="this.showaddStock">

    <div nz-row [nzJustify]="'center'">
        <div nz-col nzSpan="8" style="border-radius: 20px ; border :1px solid;padding: 20px;">
            <form [formGroup]="StockAddForm" (ngSubmit)="onFormAddSubmit()">
                <div nz-row [nzGutter]="4">
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="'Stock Description required'">
                                <label>Description</label><br>
                                <input name="description" formControlName="description" nz-input placeholder="Description" autocomplete="off" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="'Stock Code required'">
                                <label>Stock Code</label><br>
                                <input name="stock_code" formControlName="stock_code" nz-input placeholder="Stock Code" autocomplete="off" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row [nzGutter]="4">
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="'Stock Cost required'">
                                <label>Cost <small class="text-info-style">(ex VAT)</small></label><br>
                                <input type="number" name="cost" formControlName="cost" nz-input value="1" min="1" autocomplete="off" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="'Stock Count required'">
                                <label>Quantity</label><br>
                                <input type="number" name="soh" formControlName="soh" nz-input value="1" min="1" autocomplete="off" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <div nz-row>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control [nzErrorTip]="'Stock Type Required'">
                                <nz-form-label>Stock Type</nz-form-label>
                                <nz-select formControlName="type" (ngModelChange)="assignType($event)" nzPlaceHolder="Select Type" [(ngModel)]="selectedStockType">
                                    <nz-option *ngFor="let item of stockTypes" [nzValue]="item.stockTypeId" [nzLabel]="item.stockDescription"></nz-option>
                                </nz-select>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
                <button nz-button nzType="primary" (click)="onFormAddSubmit()"><i nz-icon nzType="save" nzTheme="fill"></i>Save</button>
            </form>
        </div>
    </div>
</div>

<nz-modal [(nzVisible)]="isQtyModalVisible" [nzTitle]="'Modify Quantity : ' + modifystock.description " (nzOnCancel)="isQtyModalVisible = false" [nzFooter]="null" nzWidth="700px">
    <div *nzModalContent>
        <div nz-row nzJustify="center">
            <div nz-col nzSpan="12">
                <label>Select Reason For Stock Change</label>
                <nz-select [(ngModel)]="selectedReason">
                    <nz-option *ngFor="let item of reasons" [nzLabel]="item.reason" [nzValue]="item.reasonId"></nz-option>
                </nz-select>
            </div>
            <div nz-col nzSpan="12">
                <div>
                    <div nz-row nzJustify="center">
                        <div nz-col nzSpan="12">
                            <label>Current SOH</label><br> {{modifystock.soh}}
                        </div>
                        <div nz-col nzSpan="12">
                            <label>New SOH</label><br>
                            <nz-input-number [(ngModel)]="this.newsoh" [nzMin]="0" [nzMax]="modifystock.soh" [nzStep]="1" [nzSize]="'small'">
                            </nz-input-number>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div nz-row nzJustify="center">
            <div nz-col>
                <button nz-button nzType="primary" (click)=" updateQty(this.modifystock)">Save</button>
            </div>
        </div>
    </div>
</nz-modal>